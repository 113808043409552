import { Input } from 'antd';
import { useEffect, useRef } from 'react';

interface Props {
    searchParams: URLSearchParams;
    handleSearch: (value: string) => void;
    loading: boolean;
}

const { TextArea } = Input;

const Search = ({ ...props }: Props) => {
    const { handleSearch, searchParams, loading } = props;

    const inputRef = useRef<HTMLTextAreaElement | null>(null);

    const search = searchParams.get('search') || '';

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [loading]);

    return (
        <div className="main-search__input">
            <div style={{
                flex: 1, display: 'flex', padding: '5px 8px 0 14px', opacity: loading ? 0.5 : 1,
            }}
            >
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flex: 1,
                    marginTop: 6,
                }}
                >
                    <TextArea
                        rows={1}
                        style={{
                            all: 'unset',
                        }}
                        ref={inputRef}
                        maxLength={2048}
                        defaultValue={search}
                        disabled={loading}
                        onChange={(value) => handleSearch(value.target.value)}
                    />
                </div>
            </div>
        </div>
    );
};

export default Search;
