import {
    Col, Layout as LayoutAntd, Menu, Row, Typography,
} from 'antd';
import {
    AiFillPieChart, AiFillProject, AiOutlineAlert,
} from 'react-icons/ai';
import {
    BsCash, BsPersonFill, BsShop, BsWrench, BsX,
} from 'react-icons/bs';
import { GiHammerDrop } from 'react-icons/gi';
import {
    FaCashRegister, FaBuilding, FaFileInvoice, FaWhatsapp,
} from 'react-icons/fa';
import { IoTicketSharp } from 'react-icons/io5';
import { MdOutlineDeliveryDining } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { TbDeviceDesktopAnalytics } from 'react-icons/tb';

const { Header } = LayoutAntd;
const { SubMenu } = Menu;
const { Text } = Typography;

export const SideMenu = ({ isMenuOpen, closeMenu }: any) => {
    const navigate = useNavigate();
    return (
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
            <Header style={{ padding: '0 20px' }}>
                <Row justify="space-between" gutter={[24, 24]}>
                    <Col>
                        <div
                            onClick={closeMenu}
                            className="full__height flex-row center-row-center"
                            style={{ cursor: 'pointer' }}
                        >
                            <BsX
                                size={24}
                                color="#00B2E3"
                                className="trigger"
                                style={{ verticalAlign: 'middle' }}
                            />
                            <Text className="text-logo">
                                Picker
                            </Text>
                        </div>
                    </Col>
                </Row>
            </Header>
            <SubMenu
                key="services"
                icon={<AiFillProject size={18} />}
                title="Service Requests"
            >
                <Menu.Item
                    onClick={() => navigate('/v2/bookings/')}
                    key="services2"
                >
                    Corporate Service Requests
                </Menu.Item>
                <Menu.Item
                    onClick={() => navigate('/v2/warning-service-requests/')}
                    key="services3"
                >
                    Warning Service Requests
                </Menu.Item>
                <Menu.Item
                    onClick={() => navigate('/v2/express-delivery/')}
                    key="services4"
                >
                    Express Delivery Requests
                </Menu.Item>
            </SubMenu>
            <Menu.Item
                icon={<AiOutlineAlert size={18} />}
                onClick={() => navigate('/v2/alerts')}
                key="alerts"
            >
                Alerts
            </Menu.Item>
            <Menu.Item
                icon={<TbDeviceDesktopAnalytics size={18} />}
                onClick={() => navigate('/v2/features-monitor')}
                key="features-monitor"
            >
                Features Monitors
            </Menu.Item>
            <Menu.Item
                icon={<IoTicketSharp size={18} />}
                onClick={() => navigate('/v2/issues')}
                key="booking-issues"
            >
                Booking Issues
            </Menu.Item>
            <Menu.Item
                icon={<GiHammerDrop size={18} />}
                onClick={() => navigate('/v2/objected-requests')}
                key="objected-requests"
            >
                Objected Requests
            </Menu.Item>
            <Menu.Item
                icon={<BsPersonFill size={18} />}
                onClick={() => navigate('/v2/command-center')}
                key="command-center"
            >
                Command Center
            </Menu.Item>
            <Menu.Item
                icon={<AiFillPieChart size={18} />}
                onClick={() => navigate('/v2/reports')}
                key="reports"
            >
                Reports
            </Menu.Item>
            <Menu.Item
                icon={<BsShop size={18} />}
                onClick={() => navigate('/v2/business')}
                key="business"
            >
                Business
            </Menu.Item>
            <Menu.Item
                icon={<FaBuilding size={18} />}
                onClick={() => navigate('/v2/workspaces')}
                key="workspaces"
            >
                Workspaces
            </Menu.Item>
            <Menu.Item
                icon={<MdOutlineDeliveryDining size={18} />}
                onClick={() => navigate('/v2/small-fleet')}
                key="smallfleet"
            >
                Small Fleet
            </Menu.Item>
            <Menu.Item
                icon={<FaCashRegister size={18} />}
                onClick={() => navigate('/v2/payments')}
                key="payments"
            >
                Payments
            </Menu.Item>
            <Menu.Item
                icon={<BsCash size={18} />}
                onClick={() => navigate('/v2/receivables')}
                key="receivables"
            >
                Receivables
            </Menu.Item>
            <Menu.Item
                icon={<IoTicketSharp size={18} />}
                onClick={() => navigate('/v2/plans')}
                key="plans"
            >
                Plans
            </Menu.Item>
            <Menu.Item
                icon={<FaFileInvoice size={18} />}
                onClick={() => navigate('/v2/invoices')}
                key="invoices"
            >
                Invoices
            </Menu.Item>
            <Menu.Item
                icon={<MdOutlineDeliveryDining size={18} />}
                onClick={() => navigate('/v2/delivery-providers')}
                key="deliveryProviders"
            >
                Delivery Providers
            </Menu.Item>
            <SubMenu
                key="regions"
                icon={<AiFillProject size={18} />}
                title="Regions"
            >
                <Menu.Item
                    onClick={() => navigate('/v2/regions/add/')}
                    key="add-region"
                >
                    Add region
                </Menu.Item>
            </SubMenu>
            <Menu.Item
                icon={<BsWrench size={18} />}
                onClick={() => navigate('/v2/tiers-configuration')}
                key="tiersConfiguration"
            >
                Tiers Configuration
            </Menu.Item>
            <Menu.Item
                icon={<BsPersonFill size={18} />}
                onClick={() => navigate('/v2/users')}
                key="users"
            >
                Users
            </Menu.Item>
            <Menu.Item
                icon={<FaWhatsapp size={18} />}
                onClick={() => navigate('/v2/whatsapp-certifications')}
                key="whatsapp-certifications"
            >
                Whatsapp Certifications
            </Menu.Item>
        </Menu>
    );
};
