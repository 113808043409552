const STORAGE_COLUMNS_NAME = 'issues-columns';
const COLUMN_OPTIONS = [
    {
        value: 'workspaceName',
        label: 'Workspace',
    },
    {
        value: 'paymentMethod',
        label: 'Payment Method',
    },
    {
        value: 'amount',
        label: 'Amount',
    },
    {
        value: 'description',
        label: 'Description',
    },
];
export {
    COLUMN_OPTIONS,
    STORAGE_COLUMNS_NAME,
};
