import {
    Dropdown,
    Form,
    Menu,
    Modal,
    Typography,
    message,
    notification,
} from 'antd';
import { useState } from 'react';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import moment from 'moment';
import { IInvoice } from '../context/invoices.interface';
import ChangeStatus from './ChangeStatus';
import { updateInvoice, deleteInvoice } from '../../../api/invoices';
import { OCTOPUS_BASE_URL } from '../../../api';

const { confirm } = Modal;
const { ItemGroup } = Menu;
const { Title, Text } = Typography;

const Actions = (
    { item, setItem, fetchData }: { item: IInvoice, setItem: (item: IInvoice) => void, fetchData: () => void },
) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const handleChangeStatus = () => {
        confirm({
            width: 500,
            title: (
                <Title level={4}>
                    {`Are you sure change status of this invoice ${item.invoiceId}?`}
                </Title>
            ),
            content: (
                <ChangeStatus
                    form={form}
                    item={item}
                />
            ),
            onCancel: () => {},
            okButtonProps: { loading },
            onOk: () => {
                setLoading(true);
                const status = form.getFieldsValue();
                if (status === item.status) {
                    message.warning('Status not changed');
                    return;
                }
                updateInvoice(item._id, { ...status })
                    .then(() => {
                        notification.success({
                            message: 'Invoice status updated successfully',
                        });
                        fetchData();
                    })
                    .catch((error: any) => {
                        const description = error?.response?.data?.message || error.message;
                        notification.error({
                            message: 'Error',
                            description,
                        });
                    })
                    .finally(() => setLoading(false));
            },
        });
    };

    const handleDelete = () => {
        confirm({
            width: 500,
            title: (
                <Title level={4}>
                    {`Are you sure delete this invoice ${item.invoiceId}?`}
                </Title>
            ),
            content: (
                <Text>
                    This action cannot be undone.
                </Text>
            ),
            onCancel: () => {},
            okButtonProps: { loading },
            onOk: () => {
                setLoading(true);
                if (item.isDeleted) {
                    message.warning('Invoice already deleted');
                    return;
                }
                deleteInvoice(item._id)
                    .then(() => {
                        notification.success({
                            message: 'Invoice deleted successfully',
                        });
                        fetchData();
                    })
                    .catch((error: any) => {
                        const description = error?.response?.data?.message || error.message;
                        notification.error({
                            message: 'Error',
                            description,
                        });
                    })
                    .finally(() => setLoading(false));
            },
        });
    };

    const handleDownload = (type?: string) => {
        if (!item?._id) {
            message.error('Invoice not found');
        }
        const token = localStorage.getItem('token');
        const invoiceId = item?._id;
        const utc = (moment().utcOffset() / 60).toString();

        const baseUrl = `${OCTOPUS_BASE_URL}/reports`;
        const config = `&token=${token}&utc=${utc}`;

        let url: string = '';

        if (type === 'BOOKING') {
            url = `${baseUrl}?CSVtype=invoicesReport&paymentId=${invoiceId}${config}`;
        } else {
            url = `${baseUrl}?CSVtype=mrrTransactions&invoiceId=${invoiceId}${config}`;
        }
        window.open(url, '_blank');
    };

    const OPTIONS = [
        {
            children: 'View transactions',
            onClick: () => setItem(item),
        },
        {
            children: 'Change Status',
            onClick: () => handleChangeStatus(),
        },
        {
            children: 'Download transactions',
            onClick: () => handleDownload(item?.type),
        },
        {
            children: 'Delete Invoice',
            type: 'danger',
            onClick: () => handleDelete(),
        },
    ];

    return (
        <Dropdown
            placement="bottomRight"
            trigger={['click']}
            overlay={(
                <Menu>
                    <ItemGroup
                        key="g"
                    >
                        {
                            OPTIONS.map((option, index) => (
                                <Menu.Item key={index} {...option} />
                            ))
                        }
                    </ItemGroup>
                </Menu>
            )}
        >
            <BiDotsVerticalRounded size={24} />
        </Dropdown>
    );
};

export default Actions;
