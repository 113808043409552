import { Menu, Modal, notification } from 'antd';
import { useContext } from 'react';
import { updateIssue } from '../../../api/issues';
import { IssuesContext } from '../context/issues.context';

const { Item } = Menu;
const { confirm } = Modal;

const ChangeStatus = () => {
    const {
        item: issue,
        setData,
        setItem,
        data,
    } = useContext(IssuesContext);

    const OPTIONS = [
        { label: 'PENDING', value: 'PENDING' },
        { label: 'ACTIVE', value: 'ACTIVE' },
        { label: 'SOLVED', value: 'SOLVED' },
        { label: 'NOT SOLVED', value: 'NOT_SOLVED' },
        { label: 'REFUNDED', value: 'REFUNDED' },
    ];

    const confirmChangeStatus = (status: string) => confirm({
        title: 'Change status',
        content: 'Are you sure you want to change the status of this issue?',
        onOk: () => {
            try {
                const payload = {
                    responsableID: issue?.responsableID,
                    responsableName: issue?.responsableName,
                    status,
                    description: issue?.description,
                    companyName: issue?.bookingData?.businessName,
                    userID: issue?.userID,
                    bookingNumericId: issue?.bookingNumericId,
                    issuePublicID: issue?.issuePublicID,
                    role: 'business',
                };
                const issueID = issue?._id || '';
                updateIssue(issueID, payload)
                    .then((response) => {
                        notification.success({
                            message: 'Status changed successfully',
                        });
                        const newData = data.map((item) => {
                            if (item._id === issueID) return { ...item, status };
                            return item;
                        });
                        setData(newData);
                        setItem({ ...issue, ...response.data.data });
                    }).catch((error) => {
                        const description = error?.response?.data?.message || 'Something went wrong, please try again later';
                        notification.error({
                            message: 'Error',
                            description,
                        });
                    });
            } catch (error) {
                console.log(error);
                notification.error({
                    message: 'Error',
                    description: 'Something went wrong, please try again later',
                });
            }
        },
        okText: 'Change',
        cancelText: 'Cancel',
    });

    return (
        <Menu>
            {
                OPTIONS.map((option) => (
                    <Item
                        key={option.value}
                        onClick={() => confirmChangeStatus(option.value)}
                    >
                        {option.label}
                    </Item>
                ))
            }
        </Menu>
    );
};

export default ChangeStatus;
