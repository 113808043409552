import {
    Button,
    InputNumber,
    notification,
    Space,
    Typography,
} from 'antd';
import { useContext, useState } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { IIssue } from '../../../interfaces/issue.interface';
import { updateIssue } from '../../../api/issues';
import { IssuesContext } from '../context/issues.context';

const { Text } = Typography;

const SetRefundAmount = () => {
    const {
        item: issue,
        setData,
        setItem,
        data,
    } = useContext(IssuesContext);

    if (!issue) return null;

    const { refundAmount, status, _id: issueID } = issue as IIssue;

    const [edit, setEdit] = useState<boolean>(false);
    const [value, setValue] = useState<number>(refundAmount || 0);

    const finishEditing = () => {
        const payload = {
            responsableID: issue?.responsableID,
            responsableName: issue?.responsableName,
            status: issue?.status,
            description: issue?.description,
            companyName: issue?.bookingData?.businessName,
            userID: issue?.userID,
            bookingNumericId: issue?.bookingNumericId,
            issuePublicID: issue?.issuePublicID,
            role: 'business',
            refundAmount: value,
        };

        updateIssue(issue?._id || '', payload)
            .then((response) => {
                notification.success({
                    message: 'Refund amount updated successfully',
                });
                setItem({ ...issue, refundAmount: value });
            }).catch((error) => {
                const description = error?.response?.data?.message || 'Something went wrong, please try again later';
                notification.error({
                    message: 'Error',
                    description,
                });
            }).finally(() => setEdit(false));
    };

    return (
        <div>
            {
                edit ? (
                    <InputNumber
                        value={value}
                        onChange={(value: any) => setValue(value)}
                        style={{ width: 130 }}
                        onPressEnter={finishEditing}
                        stringMode
                        controls={false}
                        formatter={(value: any) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    />
                ) : (
                    <Space size={8}>
                        <Text>
                            {refundAmount || '0.00'}
                        </Text>
                        <Button
                            type="link"
                            size="small"
                            icon={(
                                <AiFillEdit
                                    style={{ verticalAlign: 'middle' }}
                                    size={16}
                                />
                            )}
                            onClick={() => setEdit(true)}
                            style={{ padding: 0 }}
                        />
                    </Space>
                )
            }
        </div>
    );
};

export default SetRefundAmount;
