import { useContext } from 'react';
import {
    Avatar,
    Card,
    Col,
    PageHeader,
    Row,
    Space,
    Spin,
    Typography,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import MetaData from './MetaData';
import Deliveries from './Deliveries';
import StatusUpdates from './StatusUpdates';
import { Events } from './Events';
import { GuideDetailsContext } from '../context/guide-details.context';
import { GuideOrderTable } from './GuideOrderTable';
import Information from './Information';
import Loading from '../../../widgets/Loading';

const { Text } = Typography;

const GuideDetail = () => {
    const { guide, loading } = useContext(GuideDetailsContext);

    const navigate = useNavigate();

    if (loading) return <Loading />;

    if (!guide) return null;

    return (
        <>
            <PageHeader
                className="site-page-header"
                onBack={() => navigate(-1)}
                title={`Detalle de la guía - ${guide.guideNumericId}`}
                style={{ margin: '24px 0' }}
            />

            <Row
                gutter={[20, 20]}
                style={{
                    margin: '20px 10px',
                }}
            >

                <Col span={24}>
                    <Information
                        createdAt={guide.createdAt}
                        businessName={guide.businessName}
                        businessID={guide.businessID}
                        workspaceID={guide.workspaceID}
                        workspaceName={guide.workspaceName}
                        deliveryProvider={guide.deliveryProvider || 'Not assigned'}
                        driverName={guide.driverName || 'Not assigned'}
                        driverMobile={guide.driverMobile || 'Not assigned'}
                        origin={guide.origin}
                    />

                </Col>

                <Col span={24}>
                    <Row style={{ width: '100%' }} gutter={20}>
                        <Col md={24} lg={12}>
                            <MetaData
                                header="Meta Data"
                                estimatedDeliveryTime={guide.estimatedDeliveryTime || 0}
                                totalDistance={guide.totalDistance || 0}
                                providerFee={guide.providerFee || 0.00}
                            />
                        </Col>
                        <Col md={24} lg={12}>
                            <Deliveries itemSelected={guide} />

                        </Col>
                    </Row>
                </Col>

                <Col span={24}>
                    <Row style={{ width: '100%' }} gutter={20}>
                        <Col md={24} lg={12}>
                            <GuideOrderTable guideId={guide._id} />
                        </Col>
                        <Col md={24} lg={12}>
                            <Events guideId={guide._id} expressDeliveries={guide.expressDeliveries} />
                        </Col>
                    </Row>
                </Col>

                <Col span={24}>
                    <StatusUpdates
                        statusUpdates={guide.statusUpdates || []}
                        expressDeliveries={guide.expressDeliveries}
                    />
                </Col>
            </Row>
        </>

    );
};

export default GuideDetail;
