import { PageHeader } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { TableDispersions } from '../components/CashDelivery';
import { CashDeliveryProvider } from '../components/CashDelivery/context/cashDeliveryContext';

const CashDelivery = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <div className="screen__cash-delivery">
            <PageHeader title="Payments" style={{ padding: '32px 24px 24px 0' }} />
            <CashDeliveryProvider>
                <TableDispersions params={searchParams} />
            </CashDeliveryProvider>
        </div>
    );
};

export default CashDelivery;
