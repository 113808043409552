import { Space, Typography } from 'antd';
import { FC } from 'react';
import { IExpressDelivery, IGuideEvent } from '../../../interfaces/express-delivery.interface';

const { Text } = Typography;

interface GuideEventCardProps {
    eventData: IGuideEvent;
    expressDeliveries: Partial<IExpressDelivery>[];
}

const InfoBlock: FC<{ label: string; value: string }> = ({ label, value }) => (
    <Space size={8}>
        <Text strong style={{ fontSize: '12px' }}>{label}</Text>
        <Text style={{ fontSize: '12px' }}>{value}</Text>
    </Space>
);

const GuideEventCard: FC<GuideEventCardProps> = ({ eventData, expressDeliveries }) => {
    const {
        description, driverName, driverMobile, registeredBy, type, expressDeliveryID,
    } = eventData;

    const findEDById = (id: string) => {
        const ed = expressDeliveries.find((ed) => ed._id === id);

        return ed && `(Point: ${ed.expressNumericId})`;
    };

    return (
        <Space direction="vertical">
            {description && (
                <Text>
                    {description}
                    {' '}
                    {type === 'ED_STATUS_UPDATED_COMPLETED' && expressDeliveryID && findEDById(expressDeliveryID)}
                </Text>
            )}

            {driverName && driverMobile && (
                <Space size={0} direction="vertical">
                    <InfoBlock label="Driver:" value={driverName} />
                    <Text strong style={{ fontSize: '11px', fontStyle: 'italic' }}>
                        {driverMobile}
                    </Text>
                </Space>
            )}

            {registeredBy && (
                <Space size={0} direction="vertical">
                    <InfoBlock
                        label="Registered by:"
                        value={`${registeredBy?.name || ''} ${registeredBy?.lastName || ''}`}
                    />
                    {registeredBy?.email && (
                        <Text strong style={{ fontSize: '11px', fontStyle: 'italic' }}>
                            {registeredBy.email}
                        </Text>
                    )}
                </Space>
            )}
        </Space>
    );
};

export default GuideEventCard;
