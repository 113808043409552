import {
    Form,
    Modal,
    Input,
    Space,
    notification,
} from 'antd';
import { useContext, useState } from 'react';
import { InvoicesContext } from '../../Invoices/context/invoices.context';
import { addMRRTransaction } from '../../../api/invoice-transactions';

const { TextArea } = Input;

interface IProps {
    invoice: any
    visible: boolean;
    onCancel: (transaction: any) => void;
}

const AddTransaction = ({
    visible, onCancel, invoice,
}: IProps) => {
    const { fetchData } = useContext(InvoicesContext);

    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = (values: any) => {
        setLoading(true);
        addMRRTransaction(invoice._id, values)
            .then(({ data }: any) => {
                notification.success({
                    message: 'Transaction added successfully',
                });
                fetchData();
                onCancel(data.data);
            })
            .catch((error: any) => {
                const description = error.response?.data?.message;
                notification.error({
                    message: 'Error adding transaction',
                    description,
                });
            })
            .finally(() => {
                setLoading(false);
                form.resetFields();
            });
    };

    const RULES = [
        {
            required: true,
            message: 'This field is required',
        },
    ];

    return (
        <Modal
            open={visible}
            title="Add transaction"
            onCancel={() => onCancel(null)}
            onOk={() => form.submit()}
            okText="Add"
            confirmLoading={loading}
        >
            <Space direction="vertical" size={24} style={{ width: '100%' }}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Form.Item name="business" hidden initialValue={[]} />
                    <Form.Item
                        name="amount"
                        label="Amount"
                        rules={RULES}
                    >
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item
                        name="comment"
                        label="Comment"
                    >
                        <TextArea
                            rows={4}
                            showCount
                            maxLength={250}
                        />
                    </Form.Item>
                </Form>
            </Space>
        </Modal>
    );
};

export default AddTransaction;
