import { Space, Dropdown } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { AiOutlineDown } from 'react-icons/ai';
import { IssuesContext } from '../context/issues.context';
import OperatorsList from './Operators';

const AssignOperator = () => {
    const [responsableId, setResponsableId] = useState<string>();
    const [active, setActive] = useState<boolean>(false);

    const { item } = useContext(IssuesContext);

    const handleActive = () => setActive(!active);

    const onChangeResponsable = (responsableId: string) => {
        setResponsableId(responsableId);
    };

    useEffect(() => {
        if (item?.responsableID) {
            setResponsableId(item?.responsableID);
        }
    }, []);

    return (
        <Dropdown
            trigger={['click']}
            placement="bottomRight"
            visible={active}
            overlay={(
                <OperatorsList
                    handleActive={handleActive}
                    onChangeResponsable={onChangeResponsable}
                />
            )}
        >
            <Space
                direction="horizontal"
                size={16}
                style={{
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    border: '0.5px solid #d9d9d9',
                    borderRadius: 4,
                    padding: '2px 6px',
                }}
                onClick={handleActive}
            >
                <span>
                    {
                        responsableId ? item?.responsableName : 'Unassigned'
                    }
                </span>
                <AiOutlineDown
                    size={16}
                    style={{ cursor: 'pointer', verticalAlign: 'middle' }}
                />
            </Space>
        </Dropdown>
    );
};

export default AssignOperator;
