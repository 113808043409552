import {
    Avatar,
    Button,
    Card,
    Col,
    Descriptions,
    Drawer,
    Dropdown,
    PageHeader,
    Row,
    Space,
    Tag,
    Timeline,
    Tooltip,
    Typography,
} from 'antd';
import { isUndefined, set } from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import {
    BsBookFill, BsBraces, BsThreeDots,
} from 'react-icons/bs';
import {
    FaMap, FaUser, FaStore, FaRobot,
} from 'react-icons/fa';
import { UserOutlined } from '@ant-design/icons';
import { MdOutlineHelpOutline, MdOutlineMonitorHeart } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { AiFillStar } from 'react-icons/ai';
import { openModal } from '../../actions/modals';

import { getBookingDriverDetails, getBookingRoute, getReview } from '../../api/booking';
import { BookingDetails } from '../BookingDetails';
import { OrderUpdateTable } from './OrderUpdateTable';
import { IMAGES } from '../../const/images';
import { useQuery } from '../../hooks/useQuery';
import { BookingEvent, BookingType } from '../../types/booking';
import { ModalTypes } from '../../types/modals';
import { getStatusChipClass } from '../../utils/bookings';
import { googleMapsStylesLight, routeLight } from '../Map/googleMapStyles';
import { socketInstance } from '../../context/SocketClientContext';
import socketEvents from '../../const/socketEvent';
import { showBookingRequestsModal } from './BookingRequestsModal';
import AsyncButton from '../AsyncButton/AsyncButton';
import InspectorTracking from './InspectorTracking';
import { getEventsFromTracking } from '../../api/inspector';
import { IInspectorEvent } from '../../interfaces/inspector.interface';
import { IReview } from '../../interfaces/review.interface';
import { processEvents } from '../../utils/event';
import WorkspaceDrawer from './WorkspaceDrawer';
import { Events } from './Events';
import { StatusUpdates } from './StatusUpdates';
import Actions from './Actions';
import BookingDriverDetails from './BookingDriverDetails';

interface BookingProps {
    booking: BookingType
    events: BookingEvent[]
}

const { Text } = Typography;

export const BookingContainer = ({ booking, events }: BookingProps) => {
    const query = useQuery();
    const bookingId = query.get('id');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const mapRef = useRef<google.maps.Map | null>(null);

    const [showWorkspaceDrawer, setShowWorkspaceDrawer] = useState<boolean>(false);

    const pickupPosition = booking?.pickupCoordinates?.coordinates;
    const pickupCoordinates = pickupPosition && { lat: pickupPosition[1], lng: pickupPosition[0] };

    const deliveryPosition = booking?.deliveryCoordinates?.coordinates;
    const deliveryCoordinates = deliveryPosition && { lat: deliveryPosition[1], lng: deliveryPosition[0] };

    const onLoad = (map: google.maps.Map) => {
        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend(pickupCoordinates);
        bounds.extend(deliveryCoordinates);
        map.fitBounds(bounds);
        mapRef.current = map;
    };

    useEffect(() => {
        if (!bookingId) return;
        getEventsFromTracking(bookingId).then(({ data: { data } }) => {
            let events = data as IInspectorEvent[];
            events = processEvents(events);
        });
    }, [bookingId, dispatch]);

    useEffect(() => {
        if (!bookingId) return;
        getBookingRoute(bookingId)
            .then(({ data }) => {
                const points = data.data;
                const route = points.map((point: any) => ({
                    lat: point.latitude,
                    lng: point.longitude,
                }));

                const routePolyline = new google.maps.Polyline({
                    path: route,
                    ...routeLight,
                });

                routePolyline.setMap(mapRef.current);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [bookingId, dispatch]);

    useEffect(() => {
        socketInstance.on(socketEvents.BOOKING_SEND_TO_ADMIN, (payload) => {
            if (payload.booking._id === bookingId) {
                window.location.reload();
            }
        });
        return () => {
            socketInstance.off(socketEvents.BOOKING_SEND_TO_ADMIN);
        };
    }, []);

    const isOnBatch = !isUndefined(booking.isActiveOnBatch) && !booking.isActiveOnBatch;

    const DELIVERY_PRICE_ITEMS = [
        {
            label: 'Base Fare',
            value: `$${booking.priceBreakUp.baseFare.toFixed(2)}`,
            helpText: 'Costo base del servicio',
        },
        {
            label: 'Per Minute Charge',
            value: `$${booking.priceBreakUp.perMinCharge.toFixed(2)}`,
            helpText: 'Cobro adicional por el tiempo extra (Tiempo extra transcurrido en Arrived At Pickup)',
        },
        {
            label: 'Per Km Charge',
            value: `$${booking.priceBreakUp.perKmCharge.toFixed(2)}`,
            helpText: 'Cobro por la distancia extra',
        },
        {
            label: 'Service Fee',
            value: `$${booking.priceBreakUp.comission.toFixed(2)}`,
            helpText: 'Valor de la comisión. 10% del order amount generado por esa orden de compra',
        },
        {
            label: 'Total delivery cost',
            value: `$${booking.getDeliveryFee().toFixed(2)}`,
            helpText: 'Costo total del servicio',
        },
    ];

    const DISCOUNT_ITEMS = [
        {
            label: 'Promo',
            value: `$${booking.getPromoDiscount()}`,
            helpText: 'Descuento obtenido por una promoción de Picker activa',
        },
        {
            label: 'Referral',
            value: `$${booking.getReferralDiscount()}`,
            helpText: 'Descuento obtenido por haber sido referido por otro usuario de Picker',
        },
        {
            label: 'Total discounts',
            value: `$${booking.getTotalDiscount()}`,
            helpText: 'Descuento total que se aplicará al envío',
        },
    ];
    const itPhotoProofDelivery = booking.proofOfDeliveryType === 'PHOTO';

    const showEditPriceBreakupModal = () => {
        dispatch(openModal(ModalTypes.EditPriceBreakup));
    };

    const onBackHandle = () => {
        if (location.key !== 'default') navigate(-1);
        else navigate('/v2/bookings');
    };

    const getLabelClass = (status: string) => {
        let className = '';
        switch (status) {
        case 'ACCEPTED':
        case 'WAY_TO_DELIVER':
        case 'RETURNING':
        case 'COMPLETED':
            className = 'marker-label left';
            break;
        case 'ARRIVED_AT_PICKUP':
        case 'ARRIVED_AT_DELIVERY':
        case 'RETURNED':
            className = 'marker-label right';
            break;
        default:
            className = 'marker-label';
        }
        return className;
    };

    return (
        <div>
            {showWorkspaceDrawer && (
                <WorkspaceDrawer
                    workspaceID={booking?.workspaceID}
                    open={showWorkspaceDrawer}
                    onClose={() => setShowWorkspaceDrawer(false)}
                />
            )}
            <PageHeader
                className="site-page-header"
                onBack={onBackHandle}
                title={`Pedido: ${booking?.bookingNumericId || '0000000000'}`}
                subTitle={booking && <Tag color="magenta">{booking.statusText}</Tag>}
                extra={[
                    <Dropdown
                        placement="bottomRight"
                        overlay={<Actions />}
                        // @ts-ignore
                        onClick={(e: any) => { e.stopPropagation(); }}
                    >
                        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                            <Button
                                type="primary"
                                icon={<BsThreeDots style={{ verticalAlign: 'middle' }} />}
                            />
                        </a>
                    </Dropdown>,
                    <Tooltip title="3PD Monitor">
                        <Button
                            type="primary"
                            icon={<FaRobot size={16} />}
                            onClick={() => navigate(`/v2/booking/${bookingId}/monitor`)}
                        />
                    </Tooltip>,
                ]}
                style={{ margin: '24px 0' }}
            />
            <Row gutter={[20, 20]}>
                <Col xs={24} lg={12}>
                    <BookingDetails />
                </Col>
                <Col xs={24} lg={12} style={{ minHeight: 500 }}>
                    <GoogleMap
                        id="map"
                        onLoad={onLoad}
                        mapContainerStyle={{ height: '100%', width: '100%' }}
                        options={{
                            styles: googleMapsStylesLight,
                        }}
                        zoom={10}
                    >
                        {pickupCoordinates && (
                            <Marker
                                position={pickupCoordinates}
                                icon={{
                                    url: IMAGES.icons.store_location,
                                    scaledSize: new window.google.maps.Size(40, 40),
                                }}
                                zIndex={99}
                            />
                        )}
                        {deliveryPosition && (
                            <Marker
                                position={deliveryCoordinates}
                                icon={{
                                    url: IMAGES.icons.customer_location,
                                    scaledSize: new window.google.maps.Size(40, 40),
                                }}
                                zIndex={99}
                            />
                        )}
                        {
                            booking.statusUpdates.map((status) => {
                                // am or pm
                                const date = moment(status.timeStamp).format('hh:mm:ss A');
                                const textLabel = `${status.newStatusText} - ${date}`;
                                return status?.driverLocation && (
                                    <Marker
                                        key={status.newStatusText}
                                        position={status?.driverLocation || { lat: 0, lng: 0 }}
                                        icon={{
                                            url: IMAGES.icons.pinCyan,
                                            scaledSize: new window.google.maps.Size(30, 30),
                                        }}
                                        label={{
                                            text: textLabel,
                                            color: '#00B2E3',
                                            fontSize: '12px',
                                            className: getLabelClass(status.newStatusText),
                                        }}
                                        zIndex={1}
                                    />
                                );
                            })
                        }
                    </GoogleMap>
                </Col>
                <Col sm={24} md={8}>
                    <Card
                        style={{ height: '100%' }}
                        title={(
                            <Space size={8}>
                                <Avatar
                                    shape="square"
                                    size={16}
                                    src={(
                                        booking.customerImage?.original || <FaUser size={16} />
                                    )}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                />
                                {' '}
                                Customer Information
                            </Space>
                        )}
                    >
                        <Row align="bottom" gutter={20}>
                            <Col flex={1}>
                                <Descriptions className="user-table" title="" size="small">
                                    <Descriptions.Item span={3} label="Name">{booking.customerName}</Descriptions.Item>
                                    <Descriptions.Item span={3} label="Email">{booking.customerEmail}</Descriptions.Item>
                                    <Descriptions.Item span={3} label="Phone No">{booking.customerMobile}</Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col sm={24} md={8}>
                    <Card
                        style={{ height: '100%' }}
                        title={(
                            <Space size={8}>
                                <Avatar
                                    shape="square"
                                    size={16}
                                    src={(
                                        booking.driverImage?.original || <FaUser size={16} />
                                    )}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                />
                                {' '}
                                Driver Information
                            </Space>
                        )}
                    >
                        <Row align="bottom" gutter={20}>
                            <Col flex={1}>
                                <Descriptions className="user-table" title="" size="small">
                                    <Descriptions.Item span={3} label="Name">{booking.driverName}</Descriptions.Item>
                                    <Descriptions.Item span={3} label="Email">{booking.driverEmail}</Descriptions.Item>
                                    <Descriptions.Item span={3} label="Phone">{booking.driverMobile}</Descriptions.Item>
                                    {isOnBatch && (
                                        <Descriptions.Item span={3} label="In batch">Yes</Descriptions.Item>
                                    )}
                                </Descriptions>
                                {
                                    booking?.deliveryProvider === 'UBER_DAAS' && (
                                        <BookingDriverDetails bookingID={booking._id} />
                                    )
                                }
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col sm={24} md={8}>
                    <Card
                        style={{ height: '100%' }}
                        title={(
                            <Space size={8}>
                                <Avatar
                                    shape="square"
                                    size={16}
                                    src={(
                                        booking.businessImage?.original || <FaStore size={16} />
                                    )}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                />
                                {' '}
                                Business Information
                            </Space>
                        )}
                    >
                        <Row align="bottom" gutter={20}>
                            <Col flex={1}>
                                <Descriptions className="user-table" title="" size="small">
                                    <Descriptions.Item span={3} label="Company Name">{booking.businessName}</Descriptions.Item>
                                    <Descriptions.Item span={3} label="Phone No">{booking.businessMobile}</Descriptions.Item>
                                    <Descriptions.Item span={3} label="Business Unique Id">{booking.businessUniqueID}</Descriptions.Item>
                                    <Descriptions.Item span={3} label="Booking Creator">{booking.userBookingCreator}</Descriptions.Item>
                                    <Descriptions.Item span={3} label="Workspace Name">
                                        <Button
                                            type="link"
                                            size="small"
                                            style={{ padding: 0, margin: 0 }}
                                            onClick={() => setShowWorkspaceDrawer(true)}
                                        >
                                            {booking.workspaceName || '-'}
                                        </Button>
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col sm={24} md={24}>
                    <Card
                        style={{ height: '100%' }}
                        title="Price Breakup"
                        extra={(
                            <Button
                                onClick={showEditPriceBreakupModal}
                                size="small"
                            >
                                Edit
                            </Button>
                        )}
                    >
                        <Row justify="center" gutter={[0, 20]}>
                            <Col xs={24} md={6}>
                                <Descriptions title="Product cost" className="user-table">
                                    <Descriptions.Item
                                        span={3}
                                        label={(
                                            <Tooltip overlay="Valor del producto a enviar. Ingresado por el negocio">
                                                Order Fee
                                                {' '}
                                                <MdOutlineHelpOutline color="#ddd" size={15} style={{ verticalAlign: 'middle' }} />
                                            </Tooltip>
                                        )}
                                    >
                                        $
                                        {booking.orderAmount.toFixed(2)}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label={(
                                            <Tooltip overlay="Valor del producto transportado">
                                                Business Order Amount
                                                {' '}
                                                <MdOutlineHelpOutline color="#ddd" size={15} style={{ verticalAlign: 'middle' }} />
                                            </Tooltip>
                                        )}
                                    >
                                        $
                                        {booking.priceBreakUp.businessOrderAmount?.toFixed(2)}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label={(
                                            <Tooltip overlay="Valor final del servicio cobrado al comercio">
                                                Business Delivery Fee
                                                {' '}
                                                <MdOutlineHelpOutline color="#ddd" size={15} style={{ verticalAlign: 'middle' }} />
                                            </Tooltip>
                                        )}
                                    >
                                        $
                                        {booking.priceBreakUp.businessDeliveryFee?.toFixed(2)}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>
                            <Col xs={24} md={6}>
                                <Descriptions title="Discount" className="user-table">
                                    {DISCOUNT_ITEMS.map((item) => (
                                        <Descriptions.Item
                                            key={item.label}
                                            span={3}
                                            label={(
                                                <Tooltip overlay={item.helpText}>
                                                    {item.label}
                                                    {' '}
                                                    {item.helpText ? <MdOutlineHelpOutline color="#ddd" size={15} style={{ verticalAlign: 'middle' }} /> : ''}
                                                </Tooltip>
                                            )}
                                        >
                                            {item.value}
                                        </Descriptions.Item>
                                    ))}
                                </Descriptions>
                            </Col>
                            <Col xs={24} md={6}>
                                <Descriptions title="Delivery Cost" className="user-table">
                                    {DELIVERY_PRICE_ITEMS.map((item) => (
                                        <Descriptions.Item
                                            key={item.label}
                                            span={3}
                                            label={(
                                                <Tooltip overlay={item.helpText}>
                                                    {item.label}
                                                    {' '}
                                                    {item.helpText ? <MdOutlineHelpOutline color="#ddd" size={15} style={{ verticalAlign: 'middle' }} /> : ''}
                                                </Tooltip>
                                            )}
                                        >
                                            {item.value}
                                        </Descriptions.Item>
                                    ))}
                                </Descriptions>
                            </Col>
                            <Col xs={24} md={6}>
                                <Descriptions title="Total Pay" className="user-table">
                                    <Descriptions.Item
                                        span={3}
                                        label={(
                                            <Tooltip overlay="Valor total cobrado al consumidor final incluyendo descuentos">
                                                Total Pay
                                                {' '}
                                                <MdOutlineHelpOutline color="#ddd" size={15} style={{ verticalAlign: 'middle' }} />
                                            </Tooltip>
                                        )}
                                    >
                                        $
                                        {booking.getTotalPay().toFixed(2)}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col sm={24} md={8}>
                    <Card
                        style={{ height: '100%' }}
                        title={(
                            <div>
                                <BsBookFill />
                                {' '}
                                Booking Details
                            </div>
                        )}
                        extra={(
                            <AsyncButton
                                onClick={() => showBookingRequestsModal(bookingId as string)}
                                size="small"
                            >
                                <BsBraces />
                            </AsyncButton>
                        )}
                    >
                        <Row align="middle" gutter={20} justify="center">
                            {
                                booking.isProofOfDeliveryApproved && itPhotoProofDelivery
                                    && (
                                        <Col>
                                            <Avatar size={100} src={booking.proofOfDelivery} />
                                        </Col>
                                    )
                            }
                            {
                                booking.proofValidationCode
                                        && (
                                            <Col>
                                                <Typography.Title>
                                                    {' '}
                                                    {booking.proofValidationCode}
                                                    {' '}
                                                </Typography.Title>
                                            </Col>
                                        )
                            }
                            <Col flex={1}>
                                <Descriptions className="user-table">
                                    <Descriptions.Item span={3} label="Status">
                                        <Tag {...getStatusChipClass(booking.statusText)}>{booking.statusText}</Tag>
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label="Method">{booking.paymentMethod}</Descriptions.Item>
                                    <Descriptions.Item span={3} label="Vehicle Type">{booking.carName}</Descriptions.Item>
                                    <Descriptions.Item span={3} label="Booking Type">{booking.carName}</Descriptions.Item>
                                    <Descriptions.Item span={3} label="Delivery Provider">{booking.deliveryProvider}</Descriptions.Item>
                                    <Descriptions.Item span={3} label="Delivery Confirmation">{booking.proofOfDeliveryType}</Descriptions.Item>
                                    <Descriptions.Item span={3} label="Proof of Delivery">{booking.requiresProofOfDelivery ? 'Yes' : 'No'}</Descriptions.Item>
                                    <Descriptions.Item span={3} label="Operation Bonus">
                                        $
                                        {booking.priceBreakUp.operationBonus?.toFixed(2)}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label="External ID">{booking.externalBookingId}</Descriptions.Item>
                                    <Descriptions.Item span={3} label="Notes">{booking.bookingNotes}</Descriptions.Item>
                                    <Descriptions.Item span={3} label="Tip Amount">
                                        $
                                        {(booking.priceBreakUp.tip || 0).toFixed(2)}
                                        {' '}
                                        {booking.currency}
                                    </Descriptions.Item>
                                    {(booking.rating)
                                    && (
                                        <>
                                            <Descriptions.Item span={3} label="Rating">
                                                <span>
                                                    {
                                                        [...Array(5)].map((_, index) => (
                                                            <AiFillStar
                                                                key={index}
                                                                size={18}
                                                                style={{
                                                                    color: index < booking.rating || 0 ? '#FFC107' : '#E4E4E4',
                                                                    marginRight: 2,
                                                                }}
                                                            />
                                                        ))
                                                    }
                                                </span>
                                            </Descriptions.Item>
                                            <Descriptions.Item span={3} label="Review">
                                                {booking?.review || 'NA'}
                                            </Descriptions.Item>

                                        </>
                                    ) }
                                </Descriptions>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col sm={24} md={8}>
                    <Card
                        style={{ height: '100%' }}
                        title="Time - Distance Breakup"
                    >
                        <Descriptions className="user-table">
                            <Descriptions.Item span={3} label="Booking Date">
                                {booking.getBookingDate()}
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label="Requested At">
                                {moment(booking.createdAt).local().format('HH:mm:ss')}
                            </Descriptions.Item>
                            {booking.isCompleted && (
                                <Descriptions.Item span={3} label="Completed At">
                                    {booking.getCompletedAt()}
                                </Descriptions.Item>
                            )}
                            {booking.isCancelled && (
                                <Descriptions.Item span={3} label="Cancelled At">
                                    {booking.getCancelledAt()}
                                </Descriptions.Item>
                            )}
                            <Descriptions.Item span={3} label="Total Service Time">
                                {booking.getRequestTotalTime()}
                                {' '}
                                min.
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label="Total Billed Time">
                                {booking.getTotalBilledTime()}
                                {' '}
                                min
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label="Billed Distance">
                                {(booking.distanceBreakUp && booking.distanceBreakUp.billedDistance)
                                    ? booking.distanceBreakUp.billedDistance.toFixed(2)
                                    : 'N/A'}
                                km.
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label="Real Distance">
                                {booking.distanceBreakUp?.realDistance ? booking.distanceBreakUp?.realDistance.toFixed(2) : 'N/A'}
                                {' '}
                                km.
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label="Exact Distance">
                                {booking.distanceBreakUp?.exactDistance ? booking.distanceBreakUp?.exactDistance.toFixed(2) : 'N/A'}
                                {' '}
                                km.
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Col>
                <Col span={24}>
                    <StatusUpdates
                        statusUpdates={booking.statusUpdates}
                        booking={booking}
                    />
                </Col>
                <Col span={24}>
                    <OrderUpdateTable
                        bookingId={booking._id}
                    />
                </Col>
                <Col xs={24} md={12}>
                    <Card
                        title={(
                            <div>
                                <FaMap />
                                {' '}
                                Directions
                            </div>
                        )}
                    >
                        <Row gutter={[20, 20]}>
                            <Col xs={24} md={12}>
                                <Descriptions title="Pickup Details" className="user-table">
                                    <Descriptions.Item span={3} label="Address">
                                        {booking.pickupAddress || 'N/A'}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label="Address Reference">
                                        {booking.pickupReferences || 'N/A'}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label="City">
                                        {booking.pickupCity || 'N/A'}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label="State">
                                        {booking.pickupState || 'N/A'}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label="Zipcode">
                                        {booking.pickupZipCode || 'N/A'}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>
                            <Col xs={24} md={12}>
                                <Descriptions title="Delivery Details" className="user-table">
                                    <Descriptions.Item span={3} label="Address">
                                        {booking.deliveryAddress || 'N/A'}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label="Address Reference">
                                        {booking.deliveryReferences || 'N/A'}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label="City">
                                        {booking.deliveryCity || 'N/A'}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label="State">
                                        {booking.deliveryState || 'N/A'}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label="Zipcode">
                                        {booking.deliveryZipCode || 'N/A'}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label="Delivery Zone">
                                        {booking.deliveryZone || 'N/A'}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={24} md={12}>
                    <Events bookingId={booking._id} />
                    {/* <Card
                        title={(
                            <div>
                                <BsBell />
                                {' '}
                                Events
                            </div>
                        )}
                        extra={(
                            <AsyncButton
                                onClick={showDrawer}
                                size="small"
                            >
                                See Inspector
                            </AsyncButton>
                        )}
                    >
                        <div>
                            <Drawer
                                title="Inspector Events"
                                placement="right"
                                closable
                                onClose={closeDrawer}
                                visible={drawerVisible}
                                width={400}
                            >
                                {inspectorEvents?.length > 0 ? (
                                    <InspectorTracking inspectorEvent={inspectorEvents} />
                                ) : (
                                    <span>NO USER EVENTS RECORDED IN TRACKING</span>
                                )}
                            </Drawer>
                        </div>
                        <Timeline mode="left">
                            {events.map((ev: BookingEvent) => {
                                const event = new BookingEvent(ev);
                                const isAdminEvent = event.isAdmin;
                                return (
                                    <Timeline.Item key={event.date} label={event.date}>
                                        <div>
                                            {event.description}
                                        </div>
                                        {event.registeredByText && (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {isAdminEvent
                                                && (
                                                    <div style={{ marginRight: '8px' }}>
                                                        <UserOutlined style={{ fontSize: '1.5em', color: 'cyan' }} />
                                                    </div>
                                                )}
                                                <div>
                                                    <b>Registered by: </b>
                                                    {event.registeredByText}
                                                </div>
                                            </div>
                                        )}
                                    </Timeline.Item>
                                );
                            })}
                        </Timeline>
                    </Card> */}
                </Col>
            </Row>
        </div>
    );
};
