import moment from 'moment';
import {
    Row,
    Col,
    Card,
    Space,
    Typography,
    PageHeader,
    Divider,
} from 'antd';
import { useEffect, useState } from 'react';
import {
    IExpressDelivery,
    IStatusUpdate,
} from '../../../interfaces/express-delivery.interface';

const { Title, Text } = Typography;

interface Props {
    statusUpdates: IStatusUpdate[];
    expressDeliveries: Partial<IExpressDelivery>[];
}

const StatusUpdates = ({ statusUpdates, expressDeliveries }: Props) => {
    const [timeLine, setTimeLine] = useState<any[]>([]);

    const edStatusOmitted = [
        'ARRIVED_AT_PICKUP',
    ];

    const getDate = (date: Date) => moment(date).format('ddd DD MMM');
    const getTime = (date: Date) => moment(date).format('HH:mm:ss');

    const mergeStatusUpdates = (edStatusUpdates: IStatusUpdate[] | []) => {
        if (!statusUpdates.length) return;

        const mergedStatusUpdates = [...statusUpdates, ...edStatusUpdates];
        const sortedStatusUpdates = mergedStatusUpdates.sort((a: any, b: any) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

        setTimeLine(sortedStatusUpdates);
    };

    const mergeEDStatusUpdates = () => {
        const mergedExpressDeliveriesStatusUpdates = expressDeliveries?.reduce<IStatusUpdate[]>((acc, curr) => {
            if (curr.statusUpdates) {
                const edStatusUpdates = curr.statusUpdates.map((update) => ({
                    ...update,
                    point: curr.expressNumericId,
                    customer: `${curr.customerName} ${curr.customerLastName}`,
                    description: curr.description,
                    showItem: edStatusOmitted.includes(update.newStatus),
                }));
                return [
                    ...acc,
                    ...edStatusUpdates,
                ];
            }
            return acc;
        }, []) || [];

        mergeStatusUpdates(mergedExpressDeliveriesStatusUpdates || []);
    };

    useEffect(() => {
        mergeEDStatusUpdates();
    }, [statusUpdates, expressDeliveries]);

    return (
        <Card
            style={{ height: '100%', width: '100%' }}
            title={(
                <Space size={8}>
                    Status Updates
                </Space>
            )}
        >
            <Space direction="vertical" size={12} style={{ width: '100%' }}>

                <Row gutter={[20, 20]}>
                    {
                        timeLine.map((status) => (
                            <Col key={status.timeStamp} lg={6} md={10}>
                                <Card>
                                    <Card.Meta
                                        title={`${status.point ? `Point ${status.point}` : 'Guide:'} ${status.newStatus}`}
                                        description={(
                                            <Space>
                                                <Text>
                                                    {getDate(status.timestamp)}
                                                </Text>
                                                <Text type="secondary"> at </Text>
                                                <Text>
                                                    {getTime(status.timestamp)}
                                                </Text>
                                            </Space>
                                        )}
                                    />
                                </Card>
                            </Col>
                        ))
                    }
                </Row>
            </Space>
        </Card>
    );
};

export default StatusUpdates;
