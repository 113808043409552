/* eslint-disable no-unused-vars */
import moment from 'moment';
import 'moment/locale/es';
import {
    Form,
    Menu,
    message,
    Modal,
    notification,
    Space,
    Typography,
} from 'antd';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteDispersion, payDispersion, updateDispersion } from '../../../api/dispersions';
import { IDispersion } from '../../../interfaces/dispersion.interface';
import { CashDeliveryContext } from '../context/cashDeliveryContext';
import { getOneWorkspace } from '../../../api/workspaces';
import ChangeStatus from './ChangeStatus';
import DeleteDispersion from './DeleteDispersion';

interface IMenuItem {
    children: string;
    onClick: () => void;
}

type MenuItems = IMenuItem[];

const { ItemGroup, Item } = Menu;
const { confirm } = Modal;
const { Text, Title } = Typography;

const DispersionMenu = ({ dispersion }: { dispersion: IDispersion }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();
    const {
        amount, workspaceName, transactions, currency,
    } = dispersion;
    const { fetchDispersions } = useContext(CashDeliveryContext);

    const handlePayDispersion = async () => {
        confirm({
            width: 500,
            title: (
                <Title level={4}>
                    Are you sure to pay this dispersion?
                </Title>
            ),
            content: (
                <Space direction="vertical" size={8}>
                    <Text>
                        {`Payment will be made to ${workspaceName.toUpperCase()}  in the amount of $${amount}${currency}`}
                    </Text>
                    <Text>
                        {`Total transactions: ${transactions}`}
                    </Text>
                </Space>
            ),
            onCancel: () => {},
            onOk: () => {
                setLoading(true);
                moment.locale('es');
                const payload: any = {
                    ...dispersion,
                    startDate: moment(dispersion.startDate).format('dddd, D MMM'),
                    endDate: moment(dispersion.endDate).format('dddd, D MMM'),
                    payedDate: moment(new Date()).format('dddd, D MMM'),
                    amount: dispersion.amount.toFixed(2),
                };
                payDispersion(payload._id, payload)
                    .then((response: any) => {
                        console.log({ response });
                        notification.success({
                            message: 'Dispersion paid successfully',
                        });
                        fetchDispersions();
                    })
                    .catch((error: any) => {
                        const descritpion = error?.response?.data?.message || error.message;
                        notification.error({
                            message: 'Error',
                            description: descritpion,
                        });
                    })
                    .finally(() => setLoading(false));
            },
        });
    };

    const handleChangeStatus = async () => {
        confirm({
            width: 500,
            title: (
                <Title level={4}>
                    Are you sure change status of this dispersion?
                </Title>
            ),
            content: (
                <ChangeStatus
                    form={form}
                    dispersion={dispersion}
                />
            ),
            onCancel: () => {},
            onOk: () => {
                const status = form.getFieldValue('status');

                if (status === dispersion.status) {
                    message.warning('Status not changed');
                    return;
                }

                updateDispersion(dispersion._id, { status })
                    .then(() => {
                        notification.success({
                            message: 'Dispersion status updated successfully',
                        });
                        fetchDispersions();
                    })
                    .catch((error: any) => {
                        const description = error?.response?.data?.message || error.message;
                        notification.error({
                            message: 'Error',
                            description,
                        });
                    })
                    .finally(() => setLoading(true));
            },
        });
    };

    const handleDeleteDispersion = async () => {
        confirm({
            width: 500,
            title: (
                <Title level={4}>
                    Are you sure to delete this dispersion?
                </Title>
            ),
            content: (
                <DeleteDispersion
                    form={form}
                    dispersion={dispersion}
                />
            ),
            onCancel: () => {},
            onOk: () => {
                setLoading(true);
                const reason = form.getFieldValue('reason');

                deleteDispersion(dispersion._id, { reason })
                    .then(() => {
                        notification.success({
                            message: 'Dispersion deleted successfully',
                        });
                        form.resetFields();
                        fetchDispersions();
                    })
                    .catch((error: any) => {
                        const description = error?.response?.data?.message || error.message;
                        notification.error({
                            message: 'Error',
                            description,
                        });
                    })
                    .finally(() => setLoading(true));
            },
        });
    };

    const MENU: MenuItems = [
        {
            children: 'View dispersion',
            onClick: () => navigate(`/v2/payments/${dispersion._id}`),
        },
        {
            children: 'Change status',
            onClick: () => handleChangeStatus(),
        },
        {
            children: 'Pay dispersion',
            onClick: () => handlePayDispersion(),
        },
        {
            children: 'Delete dispersion',
            onClick: () => handleDeleteDispersion(),
        },
    ];

    return (
        <Menu>
            <ItemGroup key="g">
                {
                    MENU.map((item: any, index: number) => (
                        <Item key={index} {...item} />
                    ))
                }
            </ItemGroup>
        </Menu>
    );
};

export default DispersionMenu;
