import axios from 'axios';
import { OCTOPUS_BASE_URL } from '.';

export const getInvoiceTransactions = (invoiceId: string) => {
    const url = `${OCTOPUS_BASE_URL}/invoices/${invoiceId}/transactions`;
    const response = axios.get(url);
    return response;
};

export const addMRRTransaction = (invoiceId: string, payload: any) => {
    const url = `${OCTOPUS_BASE_URL}/invoices/${invoiceId}/mrr-transactions`;
    const response = axios.post(url, payload);
    return response;
};
